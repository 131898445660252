export default {
	EntryPage: {
		name: 'EntryPage'
	},
	DashboardPage: {
		name: 'DashboardPage'
	},
	EventsPage: {
		name: 'EventsPage'
	},
	ReportsPage: {
		name: 'ReportsPage'
	},
	DataExchangePage: {
		name: 'DataExchangePage'
	},
	DeviceTypesPage: {
		name: 'DeviceTypesPage'
	},
	AccessPage: {
		name: 'AccessPage'
	},
	Settings: {
		name: 'Settings'
	},
	WatermeterPage: {
		name: 'WatermeterPage'
	},
	HeatallocatorPage: {
		name: 'HeatallocatorPage'
	},
	LocationNodePage: {
		name: 'LocationNodePage'
	},
	DevicePage: {
		name: 'DevicePage'
	},
	TcpDevicePage: {
		name: 'TcpDevicePage'
	},
	Imports: {
		name: 'Imports'
	},
	ImportAES: {
		name: 'ImportAES'
	},
	ImportWmbus: {
		name: 'ImportWmbus'
	},
	ImportHeatallocator: {
		name: 'ImportHeatallocator'
	},
	ImportWmbusWizard: {
		name: 'ImportWmbusWizard'
	},
	ImportHeatallocatorWizard: {
		name: 'ImportHeatallocatorWizard'
	},
	ExportsPage: {
		name: 'ExportsPage'
	},
	ExportConfigurator: {
		name: 'ExportConfigurator'
	},
	NetworksPage: {
		name: 'NetworksPage'
	},
	NetworkPage: {
		name: 'NetworkPage'
	},
	LoraDevicePage: {
		name: 'LoraDevicePage'
	},
	ReportPage: {
		name: 'ReportPage'
	},
	ReportConfigurator: {
		name: 'ReportConfigurator'
	},
	HubPage: {
		name: 'HubPage'
	},
	HubAdd: {
		name: 'HubAdd'
	},
	HubEdit: {
		name: 'HubEdit'
	},
	HubDelete: {
		name: 'HubDelete'
	},
	MeshNetworksPage: {
		name: 'MeshNetworksPage'
	},
	MeshNetworkPage: {
		name: 'MeshNetworkPage'
	},
	MeshTerminalPage: {
		name: 'MeshTerminalPage'
	},
	RoutingTablesPage: {
		name: 'RoutingTablesPage'
	},
	MeshTransmitsPage: {
		name: 'MeshTransmitsPage'
	},
	MeshTransmitPage: {
		name: 'MeshTransmitPage'
	},
	MeshTransmitAdd: {
		name: 'MeshTransmitAdd'
	},
	MeshTransmitModification: {
		name: 'MeshTransmitModification'
	},
	SystemSearch: {
		name: 'SystemSearch'
	},
	LocationNodeModification: {
		name: 'LocationNodeModification'
	},
	MeasurementSetAdd: {
		name: 'MeasurementSetAdd'
	},
	MeasurementSetModification: {
		name: 'MeasurementSetModification'
	},
	TcpModification: {
		name: 'TcpModification'
	},
	TcpConfiguration: {
		name: 'TcpConfiguration'
	},
	WatermeterModification: {
		name: 'WatermeterModification'
	},
	WmbusModification: {
		name: 'WmbusModification'
	},
	HeatallocatorModification: {
		name: 'HeatallocatorModification'
	},
	ReportAdd: {
		name: 'ReportAdd'
	},
	ReportModification: {
		name: 'ReportModification'
	},
	ReportGenerate: {
		name: 'ReportGenerate'
	},
	NetworkAdd: {
		name: 'NetworkAdd'
	},
	NetworkModification: {
		name: 'NetworkModification'
	},
	NetworkRun: {
		name: 'NetworkRun'
	},
	NetworkConverter: {
		name: 'NetworkConverter'
	},
	NetworkDevices: {
		name: 'NetworkDevices'
	},
	NetworkSchedule: {
		name: 'NetworkSchedule'
	},
	HeatMeterPage: {
		name: 'HeatMeterPage'
	},
	HeatMeterModification: {
		name: 'HeatMeterModification'
	},
	ImportHeatmeter: {
		name: 'ImportHeatmeter'
	},
	ImportHeatmeterWizard: {
		name: 'ImportHeatmeterWizard'
	},
	UserAdd: {
		name: 'UserAdd'
	},
	UserModification: {
		name: 'UserModification'
	},
	UserPage: {
		name: 'UserPage'
	},
	GroupAdd: {
		name: 'GroupAdd'
	},
	GroupModification: {
		name: 'GroupModification'
	},
	GroupPage: {
		name: 'GroupPage'
	},
	Billing: {
		name: 'Billing'
	},
	OrdersPage: {
		name: 'OrdersPage'
	},
	OrderPage: {
		name: 'OrderPage'
	},
	OrderAdd: {
		name: 'OrderAdd'
	},
	OrderTaskPage: {
		name: 'OrderTaskPage'
	},
	DeviceConfigurationsPage: {
		name: 'DeviceConfigurationsPage'
	},
	DeviceConfigurationPage: {
		name: 'DeviceConfigurationPage'
	},
	NetworkReadCancel: {
		name: 'NetworkReadCancel'
	},
	SynchronizeUsers: {
		name: 'SynchronizeUsers'
	},
	HubConfiguration: {
		name: 'HubConfiguration'
	},
	MeshNetworkAdd: {
		name: 'MeshNetworkAdd'
	},
	MeshNetworkModification: {
		name: 'MeshNetworkModification'
	},
	MeshTerminalModification: {
		name: 'MeshTerminalModification'
	},
	MeshRoutingTableDownload: {
		name: 'MeshRoutingTableDownload'
	},
	MeshTransmitsAdd: {
		name: 'MeshTransmitsAdd'
	},
	MeshTransmitsModification: {
		name: 'MeshTransmitsModification'
	},
	MeshHubFirmwareUpdate: {
		name: 'MeshHubFirmwareUpdate'
	},
	MultiOperationsPage: {
		name: 'MultiOperationsPage'
	},
	WaterMeterMetaData: {
		name: 'WaterMeterMetaData'
	},
	HeatmeterMetaData: {
		name: 'HeatmeterMetaData'
	},
	ProtocolTemplatePage: {
		name: 'ProtocolTemplatePage'
	},
	CustomersModification: {
		name: 'CustomersModification'
	},
	ContractorsPage: {
		name: 'ContractorsPage'
	},
	ContractorsAdd: {
		name: 'ContractorsAdd'
	},
	ContractorsModification: {
		name: 'ContractorsPage'
	},
	OrderedOrdersPacks: {
		name: 'OrderedOrdersPacks'
	},
	ReceivedOrdersPacks: {
		name: 'ReceivedOrdersPacks'
	},
	OrdersAndOrdersPacksPage: {
		name: 'OrdersAndOrdersPacksPage'
	},
	OrdersPackPage: {
		name: 'OrdersPackPage'
	},
	OrdersPackPageSimple: {
		name: 'OrdersPackPageSimple'
	},
	ImportsPage: {
		name: 'ImportsPage'
	},
	PointsGroupsPage: {
		name: 'PointsGroupsPage'
	},
	PointsGroupPage: {
		name: 'PointsGroupPage'
	},
	PointAdditionalParameters: {
		name: 'PointAdditionalParameters'
	},
	ReadsModification: {
		name: 'ReadsModification'
	},
	WmbusHubsPage: {
		name: 'WmbusHubsPage'
	},
	WmbusHubPage: {
		name: 'WmbusHubPage'
	},
	WmbusHubAdd: {
		name: 'WmbusHubAdd'
	},
	WmbusHubModification: {
		name: 'WmbusHubModification'
	},
	TenantPage: {
		name: 'TenantPage'
	},
	TenantModification: {
		name: 'TenantModification'
	}
};
