import { useEffect, useState } from 'react';
import { isEqual } from 'lodash-es';
import { DatePeriod, datePeriodRangeTypesArray, DatePeriodRange } from '@sonar-web/common';

const DatePeriodRangeDays = ({
	stepIndex,
	useConfigurator,
	configuratorData,
	updateConfiguratorData,
	property,
	props
}) => {
	const { updateStepValid } = useConfigurator();
	const values = configuratorData[property];
	const [settings, setSettings] = useState(values ?? null);
	const options = !props?.withFixed
		? datePeriodRangeTypesArray.filter((d) => d.value !== DatePeriod.Fixed.name)
		: datePeriodRangeTypesArray;

	useEffect(() => {
		if (settings == null) return;
		if (isEqual(values, settings)) return;
		const isValid = validityState(settings);

		updateConfiguratorData({ [property]: settings });
		updateStepValid(stepIndex, isValid);
	}, [settings]);

	useEffect(() => {
		if (isEqual(values, settings)) return;
		const isValid = validityState(values);

		setSettings(values);
		updateStepValid(stepIndex, isValid);
	}, [values]);

	return <DatePeriodRange options={options} selected={settings} setSelected={setSettings} />;
};

export default DatePeriodRangeDays;

const validityState = (settings) => {
	const isFixedValid =
		settings?.option === DatePeriod.Fixed.name && settings?.dateFrom != null && settings?.dateTo != null;
	const isOwnValid = settings?.option === DatePeriod.Own.name && settings?.days != null;
	const isPredefined = ![DatePeriod.Fixed.name, DatePeriod.Own.name].includes(settings?.option);
	return isFixedValid || isOwnValid || isPredefined;
};
