import { useNavigate } from 'react-router';
import BaseButton from '@sonar-web/common/src/components/Buttons/BaseButton';
import EmptyPlaceholder from '@sonar-web/common/src/components/EmptyPlaceholder/EmptyPlaceholder';
import { BuildingIcon } from '@sonar-web/common';

const NoBuildingVisualization = () => {
	const navigate = useNavigate();

	const handleClick = () => navigate('/helpcenter');

	return (
		<EmptyPlaceholder icon={BuildingIcon} text='Stats_InvalidLocationStructure'>
			<BaseButton variant='outlined' name='goToHelp' onClick={handleClick} sx={{ marginTop: '1.5rem' }}>
				Stats_ShowBuildingVisualizationHelp
			</BaseButton>
		</EmptyPlaceholder>
	);
};

export default NoBuildingVisualization;
