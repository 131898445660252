import { useParams } from 'react-router';
import DeviceConfiguratorAction from '@sonar-web/common/src/features/Device/Configurator/Constants/DeviceConfiguratorAction';

export default () => {
	const { action } = useParams();

	const isPreview = action === DeviceConfiguratorAction.Preview.name;

	const isCopy = action === DeviceConfiguratorAction.Copy.name;

	const isEdit = action === DeviceConfiguratorAction.Edit.name;

	const devicePreview = action === DeviceConfiguratorAction.FromDevice.name;

	return {
		isPreview,
		isCopy,
		isEdit,
		devicePreview,
		action
	};
};
