import { useState, useEffect } from 'react';
import styled from '@emotion/styled';
import DatePeriod from '../../enums/DatePeriod';
import TileButtonSelectable from '../Tiles/TileButtonSelectable';
import DatePeriodRangeDays from './DatePeriodRangeDays';
import DateTimeRangePicker from '../../components/DateTimeRange/DateTimeRangePicker';

const Wrapper = styled('div')({
	'& .cardsGridBase': {
		flex: 0
	}
});

const Grid = styled('div')({
	display: 'flex',
	flexDirection: 'column',
	flexWrap: 'wrap',
	gap: '.6rem',
	'& .tileButtonSelectableWrapper': {
		width: '100%',
		'& > div': {
			padding: '0.6rem 1rem'
		}
	},
	'& .dateTimeRangePicker-wrapper': {}
});

const DatePeriodRange = ({ options, selected, setSelected }) => {
	const [customValue, setCustomValue] = useState(selected?.days ?? null);
	const [dateRangePickerVisible, setDateRangePickerVisible] = useState(false);
	const [fixedVisible, setFixedVisible] = useState(false);

	const customSelected = selected?.option === DatePeriod.Own.name;
	const fixedSelected = selected?.option === DatePeriod.Fixed.name;

	const onConfirm = ({ dateFrom, dateTo }) => {
		setSelected({ option: DatePeriod.Fixed.name, days: null, dateFrom, dateTo });
		setFixedVisible(false);
	};

	const handleClick = (record) => {
		if (record.value === DatePeriod.Fixed.name) {
			setFixedVisible(true);
			setSelected({
				option: DatePeriod.Fixed.name,
				days: null,
				dateFrom: selected?.dateFrom ?? null,
				dateTo: selected?.dateTo ?? null
			});
			return;
		}

		setFixedVisible(false);
		setDateRangePickerVisible(record.value === DatePeriod.Own.name);
		if (selected?.option === record.value) return;

		const customClicked = record.value === DatePeriod.Own.name;
		const newCustomValue = customClicked ? 1 : null;

		setSelected({ option: record.value, days: newCustomValue, dateFrom: null, dateTo: null });
		setCustomValue(newCustomValue);
	};

	const handleConfirmRange = (days) => {
		setCustomValue(days);
		setDateRangePickerVisible(false);
	};

	useEffect(() => {
		if (!customSelected) return;
		if (selected?.days === customValue) return;

		setSelected({ option: selected.option, days: customValue, dateFrom: null, dateTo: null });
	}, [customValue]);

	useEffect(() => {
		if (!selected) return;
		if (selected?.days === customValue) return;

		setCustomValue(selected.days);
	}, [selected?.days]);

	useEffect(() => {
		if (customSelected) setFixedVisible(false);
	}, [customSelected]);

	useEffect(() => {
		if (fixedSelected) setDateRangePickerVisible(false);
	}, [fixedSelected]);

	return (
		<Wrapper>
			<Grid>
				{options.map((option) => {
					const isOwn = option.value === DatePeriod.Own.name;
					const isFixed = option.value === DatePeriod.Fixed.name;
					return (
						<div key={option.value}>
							<TileButtonSelectable
								title={option.name}
								subtitle={option.rangeFormat(selected ?? {})}
								radio={true}
								selected={selected?.option === option.value}
								onClick={() => handleClick(option)}
								subtitleProps={{ whiteSpace: 'break-spaces' }}
								data-at={`${option.value}${isOwn ? selected?.days : ''}`}
							/>
							{isOwn && (
								<DatePeriodRangeDays
									value={customValue}
									onChange={handleConfirmRange}
									show={dateRangePickerVisible}
								/>
							)}
							{isFixed && (
								<DateTimeRangePicker
									show={fixedVisible}
									dateFrom={selected?.dateFrom ?? null}
									dateTo={selected?.dateTo ?? null}
									onConfirm={onConfirm}
								/>
							)}
						</div>
					);
				})}
			</Grid>
		</Wrapper>
	);
};

export default DatePeriodRange;
