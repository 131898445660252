import styled from '@emotion/styled';
import { Typography } from '@mui/material';
import { useLocale } from '../../hooks';
import './notification_styles.css';

const Wrapper = styled('div')({
	display: 'flex',
	alignItems: 'center',
	columnGap: '1rem',
	flex: 1
});

const NotificationWrapper = ({ Icon, iconProps = {}, title, message, wrapperProps = {} }) => {
	const { translate } = useLocale();

	return (
		<Wrapper>
			<div className='Toastify__toast-iconWrapper'>{Icon && <Icon {...iconProps} />}</div>
			<div className='Toastify__toast-contentWrapper' {...wrapperProps}>
				{typeof message === 'string' && (
					<>
						<Typography variant='caption' color='white'>
							{translate(title)}
						</Typography>
						<Typography variant='body2' color='white'>
							{translate(message)}
						</Typography>
					</>
				)}
				{typeof message === 'function' && <message />}
			</div>
		</Wrapper>
	);
};

export default NotificationWrapper;
