import { useState, useEffect, forwardRef } from 'react';
import styled from '@emotion/styled';
import moment from 'moment';
import { filterOperators } from '../../constants';
import { useIsMount, useLocale } from '../../hooks';
import { BaseDatePicker, BaseTooltip } from '..';
import { ToggleButton, ToggleButtonGroup } from '@mui/material';
import TimeRange from '../../enums/TimeRange';
import FilterOperator from '../../enums/FilterOperator';
import { DateHelpers } from '../../helpers';

//type = 'equal', 'ge', 'le'
const FiltersControlDate = ({
	member,
	label,
	stateSlice,
	type,
	onChange,
	onClearMemberSelected,
	withPredefined = false,
	disableFuture = true,
	useSingleLocalDate = false
}) => {
	const isMount = useIsMount();
	const [value, setValue] = useState(null);
	const [predefinedValue, setPredefinedValue] = useState(null);

	const generateFilterValue = (val) => {
		const date = val ?? value;
		let filterValue = [];

		switch (type) {
			case 'ge': {
				filterValue = [
					{
						member,
						value: date.hours(0).minutes(0).seconds(0).toISOString(),
						operator: FilterOperator.Ge.value,
						replace: false
					}
				];
				break;
			}
			case 'lt': {
				filterValue = [
					{
						member,
						value: date.hours(0).minutes(0).seconds(0).toISOString(),
						operator: FilterOperator.Lt.value,
						replace: false
					}
				];
				break;
			}
			case 'le': {
				filterValue = [
					{
						member,
						value: date.hours(23).minutes(59).seconds(59).toISOString(),
						operator: FilterOperator.Le.value,
						replace: false
					}
				];
				break;
			}
			default: {
				if (useSingleLocalDate) {
					filterValue = [
						{
							member,
							value: DateHelpers.dateAsIsoStringLocal(date, false, true),
							operator: FilterOperator.Equal.value,
							replace: false
						}
					];
					break;
				}

				filterValue = [
					{
						member,
						value: date.hours(0).minutes(0).seconds(0).toISOString(),
						operator: FilterOperator.Ge.value,
						replace: false
					},
					{
						member,
						value: date.hours(23).minutes(59).seconds(59).toISOString(),
						operator: FilterOperator.Le.value,
						replace: false
					}
				];
				break;
			}
		}

		return filterValue;
	};

	const handleClearValue = () => {
		setValue(null);
		setPredefinedValue(null);
	};

	const handleClearFilter = () => {
		const filterValue = generateFilterValue(value);

		onClearMemberSelected(filterValue, member.name);
		setPredefinedValue(null);
	};

	const handleSetFilter = (date) => {
		setValue(date);
		onChange(generateFilterValue(date), member.name);
		setPredefinedValue(null);
	};

	const filterNotInSlice = () => {
		const current = stateSlice[member.name];

		if (current && (type === 'equal' || !type)) return false;

		return current.find((c) => c.filterOperator === filterOperators[type]) === undefined;
	};

	const onPredefinedChange = (newValue) => {
		if (newValue === null) return handleClearFilter();

		const newDate = moment().subtract(newValue, 'months');
		setValue(newDate);
		onChange(generateFilterValue(newDate), member.name);
		setPredefinedValue(newValue);
	};

	useEffect(() => {
		if (isMount) return;
		if (
			stateSlice == null ||
			(stateSlice && Object.keys(stateSlice).length === 0) ||
			!stateSlice[member.name] ||
			(stateSlice[member.name] && filterNotInSlice())
		) {
			handleClearValue();
		}
	}, [stateSlice]);

	return (
		<>
			<BaseDatePicker
				label={label}
				disableFuture={disableFuture}
				value={value}
				onChange={(date) => handleSetFilter(date)}
				onClear={handleClearFilter}
				fullWidth={true}
			/>
			{withPredefined && <Predefined value={predefinedValue} setValue={onPredefinedChange} />}
		</>
	);
};

export default FiltersControlDate;

const StyledToggleButtonGroup = styled(ToggleButtonGroup)(({ theme }) => ({
	marginTop: '-1rem',
	alignItems: 'space-between',
	gap: '.5rem',
	'& .MuiToggleButtonGroup-grouped': {
		flex: 1,
		'&:not(:first-of-type)': {
			borderRadius: theme.shape.borderRadius,
			borderLeftColor: 'rgba(41, 61, 77, 0.12)'
		},
		'&:first-of-type': {
			borderRadius: theme.shape.borderRadius
		}
	}
}));

const Predefined = ({ value, setValue }) => {
	const { translate } = useLocale();

	const onChange = (_, newValue) => setValue(newValue);

	return (
		<StyledToggleButtonGroup
			size='small'
			value={value}
			exclusive
			onChange={onChange}
			aria-label='predefined date options'>
			<TooltipToggleButton
				value={1}
				TooltipProps={{ placement: 'bottom', text: TimeRange.Months_1.nameLong }}
				aria-label={translate(TimeRange.Months_1.nameLong)}>
				{translate(TimeRange.Months_1.nameShort)}
			</TooltipToggleButton>
			<TooltipToggleButton
				value={3}
				TooltipProps={{ placement: 'bottom', text: TimeRange.Months_3.nameLong }}
				aria-label={translate(TimeRange.Months_3.nameLong)}>
				{translate(TimeRange.Months_3.nameShort)}
			</TooltipToggleButton>
			<TooltipToggleButton
				value={6}
				TooltipProps={{ placement: 'bottom', text: TimeRange.Months_6.nameLong }}
				aria-label={translate(TimeRange.Months_6.nameLong)}>
				{translate(TimeRange.Months_6.nameShort)}
			</TooltipToggleButton>
		</StyledToggleButtonGroup>
	);
};

const TooltipToggleButton = forwardRef(({ TooltipProps, value, children, ...props }, ref) => {
	return (
		<BaseTooltip {...TooltipProps}>
			<ToggleButton ref={ref} value={value} {...props}>
				{children}
			</ToggleButton>
		</BaseTooltip>
	);
});
