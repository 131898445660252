import { useEffect, useState } from 'react';
import { Slider, OutlinedInput, Collapse } from '@mui/material';
import styled from '@emotion/styled';
import BaseButton from '../../components/Buttons/BaseButton';

const Wrapper = styled('div')(({ theme }) => ({
	display: 'flex',
	flexDirection: 'row',
	alignItems: 'center',
	marginTop: '1rem',
	padding: '2rem',
	border: `2px dotted ${theme.palette.border.main}`,
	borderRadius: theme.shape.borderRadius,
	[theme.breakpoints.down('md')]: {
		flexDirection: 'column',
		'& .saveCustomRangeBtn': {
			width: '100%'
		}
	}
}));

const Left = styled('div')(({ theme }) => ({
	display: 'flex',
	flexDirection: 'row',
	alignItems: 'center',
	flex: 1,
	gap: '1rem',
	paddingRight: '1rem',
	'& .MuiFormControl-root': {
		minWidth: 200,
		flex: 1
	},
	[theme.breakpoints.down('md')]: {
		paddingRight: 0
	}
}));

const min = 1;
const max = 90;
const step = 10;
const DatePeriodRangeDays = ({ onChange, show, value }) => {
	const [sliderValue, setSliderValue] = useState(value ?? '');

	const handleSliderChange = (e, v) => {
		e.stopPropagation();

		setSliderValue(v);
	};

	const handleInputChange = (event) => {
		setSliderValue(event.target.value === '' ? '' : Number(event.target.value));
	};

	const handleBlur = (e) => {
		const newValue = e.target.value;
		if (newValue === '' || newValue === 'e') setSliderValue(min);
		if (newValue > max) setSliderValue(max);
		if (newValue < min) setSliderValue(min);
	};

	const handleConfirm = () => {
		onChange(sliderValue);
	};

	useEffect(() => {
		if (value === sliderValue) return;
		setSliderValue(value ?? '');
	}, [value]);

	return (
		<Collapse in={show}>
			<Wrapper>
				<Left>
					<Slider
						aria-labelledby={`date_period_range_own_slider`}
						valueLabelDisplay='auto'
						value={typeof sliderValue === 'number' ? sliderValue : min}
						step={step}
						min={min}
						max={max}
						onChange={handleSliderChange}
					/>
					<OutlinedInput
						value={sliderValue}
						onChange={handleInputChange}
						onBlur={handleBlur}
						slotProps={{
							input: {
								size: 'small'
							}
						}}
						inputProps={{
							step,
							min,
							max,
							type: 'number',
							'aria-labelledby': 'input-slider'
						}}
					/>
				</Left>
				<BaseButton onClick={handleConfirm} className='saveCustomRangeBtn'>
					Exports_TimeRangeConfirm
				</BaseButton>
			</Wrapper>
		</Collapse>
	);
};

export default DatePeriodRangeDays;
