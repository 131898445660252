import React from 'react';
import { useSelector } from 'react-redux';
import moment from 'moment';
import { IconButton, InputAdornment, TextField } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import CloseIcon from '@mui/icons-material/Close';
import EventIcon from '@mui/icons-material/Event';
import { selectLocale } from '../../features/Locales/localesSlice';
import { useLocale } from '../../hooks';

const useStyles = makeStyles(() => ({
	adornmentIcon: {
		padding: '2px',
		marginRight: '-2px'
	},
	inputAdornment: {
		'& button': {
			padding: 3,
			marginRight: -4,
			marginBottom: -2
		},
		'& svg': {
			fontSize: '1.2rem'
		}
	},
	labelShrink: { width: 'auto' }
}));

const BaseDatePicker = ({
	label,
	value,
	onChange,
	onClear,
	error,
	helperText,
	FormHelperTextProps,
	fullWidth = false,
	mask = '__.__.__',
	format = moment.localeData().longDateFormat('L'),
	textFieldProps = {},
	handleOnChange = false,
	...rest
}) => {
	const classes = useStyles();
	const locale = useSelector(selectLocale);
	const { translate } = useLocale();

	const handleChange = (v) => {
		const { disableFuture, minDate } = { ...rest };
		let invalidFuture = false;
		let invalidPast = false;

		if (!v?.isValid()) return;
		if (disableFuture) invalidFuture = v.isAfter(moment(), 'day');
		if (minDate) invalidPast = v.isBefore(moment(minDate), 'day');

		if (invalidFuture) onChange(moment());
		else if (invalidPast) onChange(moment(minDate));
		else onChange(v);
	};

	return (
		<LocalizationProvider dateAdapter={AdapterMoment} adapterLocale={locale}>
			<DatePicker
				disableMaskedInput
				mask={mask}
				variant='inline'
				format={format}
				label={label ? translate(label) : null}
				value={value}
				onChange={handleOnChange ? onChange : handleChange}
				classes={{ root: classes.textfieldRoot }}
				clearable={false}
				InputLabelProps={{
					classes: {
						root: classes.labelRoot,
						shrink: classes.labelShrink
					}
				}}
				OpenPickerButtonProps={{
					sx: {
						padding: '4px',
						marginRight: '-4px'
					}
				}}
				renderInput={(props) => (
					<TextField
						{...props}
						value={value}
						fullWidth={fullWidth}
						error={error}
						helperText={translate(helperText)}
						{...textFieldProps}
						slotProps={{
							formHelperText: FormHelperTextProps
						}}
					/>
				)}
				InputAdornmentProps={{
					classes: {
						root: classes.inputAdornment
					}
				}}
				InputProps={{
					endAdornment:
						value && onClear ? (
							<InputAdornment position='end'>
								<IconButton
									size='small'
									aria-label='filter_date_clear'
									classes={{ root: classes.adornmentIcon }}
									onClick={(e) => {
										e.stopPropagation();
										onClear();
									}}>
									<CloseIcon fontSize='small' />
								</IconButton>
							</InputAdornment>
						) : (
							<InputAdornment position='end'>
								<IconButton
									size='small'
									aria-label='filter_date_select'
									classes={{ root: classes.adornmentIcon }}>
									<EventIcon fontSize='small' />
								</IconButton>
							</InputAdornment>
						)
				}}
				{...rest}
			/>
		</LocalizationProvider>
	);
};

export default BaseDatePicker;
