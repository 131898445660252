import { createSlice, createSelector } from '@reduxjs/toolkit';
import { pageDescriptor, guidEmpty, getSliceDescriptor } from '@sonar-web/common';
import { fetchLocationNodes } from './api';
import { addErrorAsync } from '@sonar-web/common/src/features/Errors/errorsSlice';

const slice = 'locationNodes';
const defaultSorters = [
	{ Member: 'locationNodeLevelType', ListSortDirection: 1 },
	{ Member: 'displayNameToSort', ListSortDirection: 1 }
];

const initialBreadcrumbs = [{ locationNodeLevelId: null, id: guidEmpty }];

export const locationNodesSliceInitialState = {
	pageDescriptor,
	pending: false,
	totalCount: 0,
	hasMore: true,
	reset: false,
	locationNodes: [],
	selected: null,
	breadcrumbs: initialBreadcrumbs,
	selectedBreadcrumbIndex: 0,
	selectedBreadcrumb: { locationNodeLevelId: null, id: guidEmpty }
};

export const locationNodesSlice = createSlice({
	name: slice,
	initialState: locationNodesSliceInitialState,
	reducers: {
		fetchLocationNodesSucces: (state, action) => {
			const { response, levels } = action.payload;
			const { elements, totalCount } = response;

			elements.forEach((e) => {
				e.level = levels.filter((l) => l.id === e.locationNodeLevelId)[0];
			});

			state.locationNodes = state.pageDescriptor.Offset === 0 ? elements : [...state.locationNodes, ...elements];
			state.totalCount = totalCount;
			state.pending = false;
			state.reset = false;
			state.hasMore = totalCount > state.pageDescriptor.Offset + state.pageDescriptor.Limit;
		},
		fetchLocationNodesPending: (state, action) => {
			state.pending = action.payload;
		},
		setResetAndReload: (state, action) => {
			state.reset = action.payload ?? true;
			state.pageDescriptor.Offset = 0;
		},
		setOffset: (state, action) => {
			state.pageDescriptor.Offset = action.payload ? state.pageDescriptor.Offset + state.pageDescriptor.Limit : 0;
		},
		setSelected: (state, action) => {
			state.selected = action.payload;
		},
		addBreadcrumb: (state, action) => {
			if (state.selectedBreadcrumb.id === action.payload.id) return;

			state.breadcrumbs = [...state.breadcrumbs, action.payload];
			state.selectedBreadcrumbIndex = state.breadcrumbs.length - 1;
			state.selectedBreadcrumb = state.breadcrumbs[state.selectedBreadcrumbIndex];
		},
		resetToBreadcrumb: (state, action) => {
			const index = action.payload;
			state.breadcrumbs = state.breadcrumbs.slice(0, index + 1);
			state.selectedBreadcrumb = state.breadcrumbs[index];
		},
		setSelectedBreadcrumbIndex: (state, action) => {
			state.selectedBreadcrumbIndex = action.payload;
		},
		deleteLocationNodeFromList: (state, action) => {
			state.locationNodes = state.locationNodes.filter((ln) => ln.id !== action.payload);
		},
		resetLocationNodes: (state) => {
			state.locationNodes = [];
			state.selectedBreadcrumbIndex = 0;
			state.selectedBreadcrumb = { locationNodeLevelId: null, id: guidEmpty };
			state.breadcrumbs = initialBreadcrumbs;
		}
	}
});

export const {
	fetchLocationNodesSucces,
	fetchLocationNodesPending,
	setResetAndReload,
	setSelected,
	setOffset,
	addBreadcrumb,
	resetToBreadcrumb,
	setSelectedBreadcrumbIndex,
	deleteLocationNodeFromList,
	resetLocationNodes
} = locationNodesSlice.actions;

export const fetchLocationNodesAsync =
	(defaultFilters = null) =>
	async (dispatch, getState) => {
		try {
			const pd = getSliceDescriptor(getState, slice, defaultFilters, defaultSorters);

			dispatch(fetchLocationNodesPending(true));
			const response = await fetchLocationNodes(pd);

			const levels = getState().locationNodeLevels.locationNodeLevels;
			dispatch(fetchLocationNodesSucces({ response, levels }));
		} catch (error) {
			dispatch(fetchLocationNodesPending(false));
			dispatch(addErrorAsync({ slice, error }));
		}
	};
export const selectLocationNodes = createSelector(
	(state) => state[slice],
	(stateSlice) => ({
		dataRows: stateSlice.locationNodes,
		pending: stateSlice.pending,
		hasMore: stateSlice.hasMore,
		reset: stateSlice.reset
	})
);

export const selectSelected = createSelector(
	(state) => state[slice],
	(stateSlice) => stateSlice.selected
);

export const selectSelectedBreadcrumb = createSelector(
	(state) => state[slice],
	(stateSlice) => stateSlice.selectedBreadcrumb
);

export const selectBreadcrumbs = createSelector(
	(state) => state[slice],
	(stateSlice) => stateSlice.breadcrumbs
);

export const selectSelectedBreadcrumbIndex = createSelector(
	(state) => state[slice],
	(stateSlice) => stateSlice.selectedBreadcrumbIndex
);

export default locationNodesSlice.reducer;
