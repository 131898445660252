import { ERROR_TYPES } from '../../constants/errorTypes';
import { SERVER_ERROR_MESSAGES } from '../../constants/errorMessages';

async function errorHandler(error) {
	if (error?.code === 'ECONNABORTED') return timeoutHandler(error);

	if (error?.code === 'USER') return userHandler(error);

	if (error.response) return await responseHandler(error);

	return defaultHandler(error);
}

export default errorHandler;

function BaseError({ message, member, code, type }) {
	return {
		message: message || '',
		member: member || '',
		code: code || 0,
		type: type || ERROR_TYPES.api
	};
}

function defaultHandler(error) {
	return new BaseError({ message: error.message, type: ERROR_TYPES.server });
}

function timeoutHandler(error) {
	return new BaseError({ message: SERVER_ERROR_MESSAGES.timeout, code: error.code, type: ERROR_TYPES.timeout });
}

function userHandler(error) {
	return new BaseError({ message: error.message, type: ERROR_TYPES.api });
}

async function responseHandler(error) {
	const response = error.response;
	let dataObj = response?.data;

	if (dataObj instanceof Blob) dataObj = handleBlobError(error, response);

	const hasReason = dataObj.reason;
	const message = dataObj.reason || response.statusText;
	const code = response.status;

	let result = new BaseError({ message, code });

	if (code == 400) {
		result.message = 'Common_RequestError';
		result.type = ERROR_TYPES.server;
	} else if (code == 401) {
		result.message = 'Common_RequestUnauthorized';
		result.type = ERROR_TYPES.server;
	} else if (code == 403) {
		result.message = 'Common_RequestForbidden';
		result.type = ERROR_TYPES.server;
	} else if (code == 404) {
		result.type = ERROR_TYPES.server;
	} else if (code == 405) {
		result.message = 'RequestNotAllowed';
		result.type = ERROR_TYPES.api;
	} else if (code == 414) {
		result.type = ERROR_TYPES.server;
		result.message = 'Common_UriTooLongError';
	} else if (code > 404 && code < 500 && hasReason) {
		const splitReason = dataObj.reason.split(':');
		result.message = splitReason.length === 1 ? splitReason[0] : splitReason[1];
		result.member = splitReason.length === 1 ? null : splitReason[0];
	} else if (code > 500) {
		result.type = ERROR_TYPES.server;
	}

	return result;
}

const handleBlobError = async (error, res) => {
	try {
		const responseText = await error.response.data.text();
		return responseText ? JSON.parse(responseText) : { reason: res.statusText };
	} catch (e) {
		return { reason: res.statusText };
	}
};
