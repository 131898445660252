import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useParams } from 'react-router';
import { Box, Fade } from '@mui/material';
import { BaseCircularProgress, ControlledTabsBase } from '../../../components';
import DeviceConfiguratorControl from './DeviceConfiguratorControl';
import {
	fetchConfigAsync,
	selectActiveTab,
	selectIsLoaded,
	setDeviceData,
	setIsLoaded
} from './deviceConfiguratorSlice';
import useConfigurator from './useConfigurator';
import DeviceConfiguratorCover from './DeviceConfiguratorCover';
import DeviceConfiguratorCancel from './DeviceConfiguratorCancel';
import ProfileSubmit from './Profiles/ProfileSubmit';
import DeviceConfiguratorSubmit from './DeviceConfiguratorSubmit';
import SubmitType from './Constants/SubmitType';
import RegistersDisabler from './RegistersDisabler';
import DeviceConfiguratorBottomBar from './DeviceConfiguratorBottomBar';
import HorizontalSpacer from '../../../components/HorizontalSpacer/HorizontalSpacer';
import SectionStatusValidation from './SectionStatusValidation';
import DeviceConfiguratorAction from './Constants/DeviceConfiguratorAction';

const DeviceConfigurator = ({
	deviceData,
	readOnly,
	headerData,
	configData,
	startingTab = 'profiles', //deviceConfiguratorProfiles, datas
	disableProfiles = false, //ukrywa menu do przejścia do profili i resetowania konfiguracji
	disableConfigurationState = false, //ukrywa informację o stanie konfiguracji (aktualna/zgodna z profilem itp)
	enableRegisterSelection = false, //włącza opcję wyboru rejestrów do zapisu w konfiguracji
	disabledRegisters = [],
	profilesApi,
	configApi,
	createProfilesComponent,
	createDatasComponent,
	CustomHeader,
	CustomBottomBar,
	CustomSubheader,
	submitType = SubmitType.SaveToDevice.name,
	onSubmit,
	onCancel,
	onSuccess,
	submitConsents,
	CustomControl
}) => {
	const dispatch = useDispatch();
	const { action } = useParams();
	const { search } = useLocation();
	const configuratorActions = useConfigurator();

	const activeTab = useSelector(selectActiveTab);
	const isLoaded = useSelector(selectIsLoaded);

	const copy = action === DeviceConfiguratorAction.Copy.name;
	const { isInProfileSubmitMode, isInSectionEditMode } = configuratorActions;

	const tabs = [
		{
			Id: 0,
			name: 'profiles',
			content: disableProfiles ? null : createProfilesComponent({ deviceData, configData, configuratorActions }),
			disableInnerTopPadding: true
		},
		{
			Id: 1,
			name: 'datas',
			content: createDatasComponent({
				profilesApi,
				deviceData,
				configData,
				configuratorActions,
				disableProfiles,
				disableConfigurationState
			}),
			disableInnerTopPadding: true,
			disableRemoveFromDOM: true
		},
		{
			Id: 2,
			name: 'deviceConfiguratorCancelTab',
			content: <DeviceConfiguratorCancel onCancel={onCancel} />,
			disableInnerTopPadding: true
		},
		{
			Id: 3,
			name: 'deviceConfiguratorSubmitTab',
			content: (
				<DeviceConfiguratorSubmit
					onSubmit={onSubmit}
					onSuccess={onSuccess}
					api={configApi}
					deviceData={deviceData}
					submitType={submitType}
					submitConsents={submitConsents}
				/>
			),
			disableInnerTopPadding: true
		}
	];

	useEffect(() => {
		dispatch(setDeviceData(deviceData));

		if (configData) dispatch(fetchConfigAsync({ api: configApi, deviceId: deviceData.id, configData }, copy));
		else if (startingTab) {
			const startingTabName = search?.split('=')[1] ?? startingTab;
			const tab = tabs.find((t) => t.name === startingTabName);
			if (tab) dispatch(setIsLoaded({ loaded: true, tabId: tab.Id }));
		}

		if (readOnly) configuratorActions.enterReadOnlyMode();
		if (enableRegisterSelection) configuratorActions.enableRegisterSelection();
	}, []);

	if (configData && isLoaded === null) return <BaseCircularProgress pending={true} />;

	return (
		<Box position='relative' display='flex' flexDirection='column' flex={1} minHeight={0} overflow='hidden'>
			<RegistersDisabler disabledRegisters={disabledRegisters} />
			<SectionStatusValidation />
			{isInProfileSubmitMode && (
				<DeviceConfiguratorCover>
					<ProfileSubmit api={profilesApi} />
				</DeviceConfiguratorCover>
			)}
			<Fade
				in={!isInProfileSubmitMode}
				enter={false}
				style={{
					display: 'flex',
					flexDirection: 'column',
					minHeight: 0,
					height: '100%'
				}}>
				<div>
					{CustomControl ? (
						<CustomControl />
					) : (
						<>
							{!isInSectionEditMode && (
								<>
									<DeviceConfiguratorControl
										CustomHeader={CustomHeader}
										deviceData={deviceData}
										headerData={headerData}
										onCancel={onCancel}
										disableProfiles={disableProfiles}
										readOnly={readOnly}
									/>
									{CustomSubheader && <CustomSubheader />}
								</>
							)}
						</>
					)}
					<ControlledTabsBase tabs={tabs} tabValue={activeTab} hidden />
					{CustomBottomBar && <HorizontalSpacer size={12} />}
				</div>
			</Fade>
			<DeviceConfiguratorBottomBar CustomBottomBar={CustomBottomBar} readOnly={readOnly} />
		</Box>
	);
};

export default DeviceConfigurator;
