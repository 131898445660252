import { useEffect, useState } from 'react';
import moment from 'moment';
import styled from '@emotion/styled';
import { Collapse, Typography } from '@mui/material';
import BaseDatePicker from '../BaseDatePicker/BaseDatePicker';
import BaseButton from '@sonar-web/common/src/components/Buttons/BaseButton';
import useLocale from '@sonar-web/common/src/hooks/useLocale';

const Wrapper = styled('div')(({ theme }) => ({
	display: 'flex',
	flexDirection: 'row',
	alignItems: 'flex-start',
	marginTop: '1rem',
	padding: '2rem',
	border: `2px dotted ${theme.palette.border.main}`,
	borderRadius: theme.shape.borderRadius,
	'& .saveCustomRangeBtn': {
		marginTop: '4px'
	},
	[theme.breakpoints.down('md')]: {
		flexDirection: 'column',
		'& .saveCustomRangeBtn': {
			marginTop: '1rem',
			width: '100%'
		}
	}
}));

const Left = styled('div')({
	display: 'flex',
	flexDirection: 'column',
	flex: 1
});

const LeftPickers = styled('div')(({ theme }) => ({
	display: 'flex',
	flexDirection: 'row',
	flexWrap: 'wrap',
	flex: 1,
	gap: '1rem',
	paddingRight: '1rem',
	'& .MuiFormControl-root': {
		minWidth: 200,
		flex: 1
	},
	[theme.breakpoints.down('md')]: {
		paddingRight: 0
	}
}));

const DateTimeRangePicker = ({ onConfirm, dateFrom, dateTo, show }) => {
	const { translate } = useLocale();
	const [currentDateFrom, setCurrentDateFrom] = useState(dateFrom);
	const [currentDateTo, setCurrentDateTo] = useState(dateTo);
	const [error, setError] = useState(false);

	const handleSetDateFrom = (date) => {
		if (!date) return;
		const dateIso = date.toISOString();

		setCurrentDateFrom(dateIso);
		if (currentDateTo) setError(dateIso > currentDateTo);
	};

	const handleSetDateTo = (date) => {
		if (!date) return;
		const dateIso = moment(date).endOf('day').toISOString();

		setCurrentDateTo(dateIso);
		if (currentDateFrom) setError(dateIso < currentDateFrom);
	};

	const handleClearDateFromFilter = () => {
		setCurrentDateFrom(null);
		setError(false);
	};

	const handleClearDateToFilter = () => {
		setCurrentDateTo(null);
		setError(false);
	};

	const handleConfirm = () => {
		onConfirm({
			dateFrom: currentDateFrom,
			dateTo: currentDateTo
		});
	};

	useEffect(() => {
		setCurrentDateFrom(dateFrom);
		setCurrentDateTo(dateTo);
	}, [show]);

	return (
		<Collapse in={show}>
			<Wrapper className='dateTimeRangePicker-wrapper'>
				<Left>
					<LeftPickers>
						<BaseDatePicker
							error={error}
							label={'Exports_DatetimeRangeFrom'}
							fullWidth
							disableFuture
							variant='inline'
							value={currentDateFrom}
							onChange={(date) => handleSetDateFrom(date)}
							onClear={handleClearDateFromFilter}
							PopperProps={{
								placement: 'top'
							}}
							textFieldProps={{
								variant: 'outlined',
								size: 'small'
							}}
						/>
						<BaseDatePicker
							error={error}
							label={'Exports_DatetimeRangeTo'}
							fullWidth
							disableFuture
							variant='inline'
							value={currentDateTo}
							onChange={(date) => handleSetDateTo(date)}
							onClear={handleClearDateToFilter}
							PopperProps={{
								placement: 'top'
							}}
							textFieldProps={{
								variant: 'outlined',
								size: 'small'
							}}
						/>
					</LeftPickers>
					{error && (
						<Typography variant='caption' color='error' className='timeRangeError'>
							{translate('Exports_DatetimeRangeInvalidDatesOrder')}
						</Typography>
					)}
				</Left>
				<BaseButton
					disabled={error || !currentDateFrom || !currentDateTo}
					onClick={handleConfirm}
					className='saveCustomRangeBtn'>
					Exports_TimeRangeConfirm
				</BaseButton>
			</Wrapper>
		</Collapse>
	);
};

export default DateTimeRangePicker;
