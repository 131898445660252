import { useLayoutEffect } from 'react';
// import { useNavigate } from 'react-router';
import styled from '@emotion/styled';
import { Typography } from '@mui/material';
import TileBase from '@sonar-web/common/src/features/Tiles/TileBase';
import TimeLicenseStatusText from '@sonar/tenants/src/TimeLicense/TimeLicenseStatusText';
import TimeLicenseStatusIcon from '../TimeLicense/TimeLicenseStatusIcon';

const Wrapper = styled('div')(({ theme }) => ({
	marginTop: '3rem',
	'& .tileBase': {
		margin: '0.5rem 0',
		padding: '0.8rem 1.5rem',
		justifyContent: 'left',
		color: theme.palette.text.primary
	},
	'& .tileBase:first-of-type': {
		marginTop: 0
	},
	'& .tileBase:last-of-type': {
		marginBottom: 0
	},
	'&.withScroll': {
		paddingRight: '1rem'
	}
}));

const Inner = styled('div')({
	display: 'flex',
	flexDirection: 'column',
	alignItems: 'flex-start',
	flex: 1
});

const TenantsList = ({ tenants, timeLicenses, onSelect, selected = null }) => {
	// const navigate = useNavigate();
	const sortedTenants = tenantsWithLicense({ tenants, timeLicenses });

	const handleClick = (t) => {
		// if (selected?.id === t.id) {
		// 	navigate('/');
		// 	return;
		// }
		onSelect(t);
	};

	useLayoutEffect(() => {
		const list = document.querySelector(`#TenantsSelectorList`);
		const resizeObserver = createObserver(list);

		resizeObserver.observe(list);
	}, []);

	return (
		<Wrapper id='TenantsSelectorList'>
			{sortedTenants.map((tenant) => {
				const isSelected = !selected ? false : selected.id === tenant.id;
				const disabled = !tenant.isValid || !tenant.timeLicence;

				return (
					<TileBase
						key={tenant.id}
						selected={isSelected}
						onClick={disabled ? null : () => handleClick(tenant)}
						hoverEffect={!disabled}
						hoverBackground={!disabled}
						selectedBackgroundEffect={true}
						renderContent={() => (
							<>
								<Inner>
									<Typography variant='subtitle1' data-at='tenantName'>
										{tenant.name}
									</Typography>
									<TimeLicenseStatusText {...tenant} />
								</Inner>
								<TimeLicenseStatusIcon {...tenant} />
							</>
						)}
					/>
				);
			})}
		</Wrapper>
	);
};

export default TenantsList;

function createObserver(list) {
	return new ResizeObserver((entries) => {
		for (let entry of entries) {
			if (!entry.borderBoxSize) break;
			const borderBoxSize = Array.isArray(entry.borderBoxSize) ? entry.borderBoxSize[0] : entry.borderBoxSize;

			if (borderBoxSize.inlineSize > entry.contentRect.width) {
				list.classList.add('withScroll');
				break;
			}

			list.classList.remove('withScroll');
		}
	});
}

function tenantsWithLicense({ tenants, timeLicenses }) {
	const tenantsWithTimeLicense = !timeLicenses
		? tenants.map((t) => ({ name: t, id: t }))
		: timeLicenses.filter((tl) => tenants.includes(tl.name));

	return tenantsWithTimeLicense.sort((a, b) => a.name.localeCompare(b.name));
}
