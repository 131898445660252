import { createSelector, createSlice } from '@reduxjs/toolkit';
import { fetchRead, addRead, editRead, deleteRead } from './readApi';
import { guidEmpty } from '@sonar-web/common';
import { addErrorAsync } from '@sonar-web/common/src/features/Errors/errorsSlice';
import { slices } from '../Constants/Module';
import ReadType from '../Enums/ReadType';

const slice = slices.watermeterRead;
const getRead = (getState) => getState().watermeterRead.read;

const initialReadData = {
	waterMeterId: guidEmpty,
	volume: '',
	date: null,
	source: '',
	type: ReadType.Instant.name,
	reverseVolume: null,
	forwardVolume: null
};

export const watermeterReadSlice = createSlice({
	name: slice,
	initialState: {
		addPending: false,
		fetchPending: false,
		fetchSuccess: false,
		deletePending: false,
		deleteSuccess: false,
		read: initialReadData
	},
	reducers: {
		fetchReadSucces: (state, action) => {
			state.read = action.payload;
			state.fetchPending = false;
			state.fetchSuccess = true;
		},
		fetchReadPending: (state) => {
			state.fetchPending = true;
			state.fetchSuccess = false;
		},
		addReadPending: (state, { payload }) => {
			state.addPending = payload;
		},
		deleteReadSucces: (state) => {
			state.deletePending = false;
			state.deleteSuccess = true;
		},
		deleteReadPending: (state, action) => {
			state.deletePending = action.payload ?? true;
		},
		setReadData: (state, action) => {
			state.read = action.payload;
		},
		resetReadData: (state) => {
			state.read = initialReadData;
			state.deleteSuccess = false;
			state.fetchSuccess = false;
		}
	}
});

export const {
	fetchReadSucces,
	fetchReadPending,
	addReadPending,
	deleteReadSucces,
	deleteReadPending,
	setReadData,
	resetReadData
} = watermeterReadSlice.actions;

export const fetchReadAsync = (id) => async (dispatch) => {
	try {
		dispatch(fetchReadPending());

		const response = await fetchRead(id);

		dispatch(fetchReadSucces(response));
	} catch (error) {
		dispatch(addErrorAsync({ slice, error }));
	}
};

export const addReadAsync = () => async (dispatch, getState) => {
	try {
		const read = { ...getRead(getState) };

		dispatch(addReadPending(true));
		await addRead({ reads: [read] });
		dispatch(addReadPending(false));
		return true;
	} catch (error) {
		dispatch(addReadPending(false));
		return await dispatch(addErrorAsync({ slice, error }));
	}
};

export const editReadAsync = () => async (dispatch, getState) => {
	try {
		dispatch(addReadPending(true));
		await editRead(getRead(getState));
		dispatch(addReadPending(false));
		return true;
	} catch (error) {
		dispatch(addReadPending(false));
		return await dispatch(addErrorAsync({ slice, error }));
	}
};

export const deleteReadAsync = (id) => async (dispatch) => {
	try {
		dispatch(deleteReadPending(true));

		await deleteRead(id);

		dispatch(deleteReadSucces());
	} catch (error) {
		dispatch(deleteReadPending(false));

		return await dispatch(addErrorAsync({ slice, error }));
	}
};

export const selectRead = createSelector(
	(state) => state[slice],
	(stateSlice) => ({
		read: stateSlice.read,
		fetchPending: stateSlice.fetchPending,
		fetchSuccess: stateSlice.fetchSuccess
	})
);

export const selectReadAddPending = createSelector(
	(state) => state[slice],
	(stateSlice) => stateSlice.addPending
);

export const selectDeleteRead = createSelector(
	(state) => state[slice],
	(stateSlice) => ({
		deletePending: stateSlice.deletePending,
		deleteSuccess: stateSlice.deleteSuccess
	})
);

export default watermeterReadSlice.reducer;
