import { useState, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { addErrorAsync } from '../features/Errors/errorsSlice';
import useRequestSuccessWarning from './useRequestSuccessWarning';

export default function useRequest({
	api,
	slice,
	callback,
	errorCallback,
	parseError,
	ownHandleError,
	skipNotificationMembers = []
}) {
	const dispatch = useDispatch();
	const { handleResponseWarning } = useRequestSuccessWarning();

	const [data, setData] = useState(null);
	const [pending, setPending] = useState(false);
	const [error, setError] = useState(null);

	const onSuccess = (res, v) => {
		setData(res);
		setPending(false);
		handleResponseWarning(res);
		if (callback) callback(res, v);
	};

	const onError = async (res) => {
		const parsedError = parseError ? parseError(res) : res;

		if (ownHandleError) {
			setPending(false);
			ownHandleError(parsedError, setError);
			return;
		}

		const err = await dispatch(addErrorAsync({ slice, error: parsedError, skipNotificationMembers }));

		setError(err);
		setPending(false);
		errorCallback?.(err);
	};

	const clearData = () => setData(null);

	const request = useCallback(
		async (params) => {
			setPending(true);
			setData(null);
			setError(null);

			try {
				const response = await api(params);
				onSuccess(response, params);
			} catch (er) {
				if (er?.message === 'canceled') return;
				onError(er);
			}
		},
		[api]
	);

	return { request, data, pending, error, clearData };
}
