import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router';
import SubmitType from './Constants/SubmitType';
import {
	submitConfigAsync,
	submitConfigOwnDataAsync,
	submitConfigEditOwnDataAsync,
	selectLastActiveTab,
	setActiveTab
} from './deviceConfiguratorSlice';
import useConfigurator from './useConfigurator';
import SaveToDevice from './SaveToDevice';
import SaveWithName from './SaveWithName';
import DeviceConfiguratorAction from './Constants/DeviceConfiguratorAction';

const DeviceConfiguratorSubmit = ({ submitType, onSubmit, onSuccess, api, deviceData, submitConsents }) => {
	const dispatch = useDispatch();
	const { action } = useParams();
	const lastActiveTab = useSelector(selectLastActiveTab);
	const { submitConfigState, submitConfigPending } = useConfigurator();

	const edit = action === DeviceConfiguratorAction.Edit.name;

	const handleBack = () => dispatch(setActiveTab(lastActiveTab));

	const handleSubmit = () => {
		if (onSubmit) {
			const submitFunc = edit ? submitConfigEditOwnDataAsync : submitConfigOwnDataAsync;

			onSubmit({
				configuratorState: submitConfigState(),
				submitFunction: (config) =>
					dispatch(submitFunc({ api, config })).then((res) => {
						if (res) onSuccess();
					}),
				edit
			});
			return;
		}

		dispatch(submitConfigAsync({ api, deviceData })).then((res) => {
			if (res) onSuccess();
		});
	};

	if (submitType === SubmitType.SaveToDevice.name)
		return <SaveToDevice handleBack={handleBack} handleSubmit={handleSubmit} pending={submitConfigPending} />;
	if (submitType === SubmitType.SaveWithName.name)
		return (
			<SaveWithName
				handleBack={handleBack}
				handleSubmit={handleSubmit}
				pending={submitConfigPending}
				isEdit={edit}
				submitConsents={submitConsents}
			/>
		);
};

export default DeviceConfiguratorSubmit;
